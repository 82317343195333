@if(show)
{
  <div class="alerts-absolute">
    <div class="p-4 gap-4 flex flex-col">
      @for(alert of alerts; track alerts)
      {
        <app-alert [alert]="alert"></app-alert>
      }
    </div>
  </div>
}
