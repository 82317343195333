import {Injectable} from '@angular/core';
import {filter, Observable, Subject} from "rxjs";
import {Alert, AlertOptions, AlertType} from "../models/alert";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alertSubject = new Subject<Alert>
  private defaultId = 'default-alert';
  private defaultOptions: AlertOptions = {
    autoClose: true,
    keepAfterRouteChange: false
  }
  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<Alert> {
    return this.alertSubject.asObservable().pipe(filter(x => x && x.id === id));
  }

  // convenience methods
  success(message: string, id = this.defaultId, options?: AlertOptions) {
    this.alert({type: AlertType.Success, message: message, id: id, options: options ?? this.defaultOptions });
  }

  error(message: string, id = this.defaultId, options?: AlertOptions) {
    this.alert({type: AlertType.Error, message: message, id: id, options: options ?? this.defaultOptions });
  }

  info(message: string, id = this.defaultId, options?: AlertOptions) {
    this.alert({type: AlertType.Info, message: message, id: id, options: options ?? this.defaultOptions });
  }

  warn(message: string, id = this.defaultId, options?: AlertOptions) {
    this.alert({type: AlertType.Warning, message: message, id: id, options: options ?? this.defaultOptions });
  }

  // main alert method
  alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;
    this.alertSubject.next(alert);
  }

  // clear alerts
  clear(id = this.defaultId) {
    this.alertSubject.next({id});
  }





}
