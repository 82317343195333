import { Injectable } from '@angular/core';
import {RestApiService} from "@makeo-packages/mkongtools";
import {Observable} from "rxjs";
import {Voyant} from "../models/voyant";

@Injectable({
  providedIn: 'root'
})
export class WaitingListService {

  public apiEndpoint: string = 'waiting-list';

  constructor(protected apiService: RestApiService) {
  }

  public getWaitingListByClientId(): Observable<any> {
    return this.apiService.get(`${this.apiEndpoint}/get-list`);
  }

  public addToWaitingList(payload): Observable<any> {
    return this.apiService.post(`${this.apiEndpoint}`, payload);
  }

  deleteFromWaitingList(id: number) {
    return this.apiService.delete(this.apiEndpoint, id);
  }
}
