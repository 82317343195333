<div
  class="inset-0 flex flex-col min-w-0 w-full overflow-y-auto"
  cdkScrollable>
  <!-- Navbar -->
  <app-nav-bar></app-nav-bar>
  <!-- Header -->
<!--  <div>-->
<!--    <div>-->
<!--      &lt;!&ndash; Title &ndash;&gt;-->
<!--      <div>-->
<!--        <h2>-->
<!--          <ng-content></ng-content>-->
<!--        </h2>-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; Actions &ndash;&gt;-->
<!--    <div>-->
<!--      <ng-content></ng-content>-->
<!--    </div>-->
<!--  </div>-->

  <!-- Main -->
  <div class="pt-22 lg:pt-[12rem] w-full">
    <!-- CONTENT GOES HERE -->
    @if (true) {
      <router-outlet></router-outlet>
    }
  </div>

<!--  TODO : masquer préprod-->
  <app-call-banner class="w-full fixed bottom-0 z-[998]"></app-call-banner>

</div>
