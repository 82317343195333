import {Component, OnInit} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {CallService} from "../../services/call.service";
import {CallTicket} from "../../models/call";
import moment from "moment";
import {WebsocketService} from "../../services/websocket.service";
import {WebSocketMessage} from "../../models/websocketMessage";
import {Router} from "@angular/router";
import {WaitingListService} from "../../services/waitingList.service";

@Component({
  selector: 'app-call-banner',
  standalone: true,
  imports: [
    MatIcon
  ],
  templateUrl: './call-banner.component.html',
  styleUrl: './call-banner.component.scss'
})
export class CallBannerComponent {
  protected currentCall: CallTicket = null;
  protected currentWaitingList = null;
  protected time: number;
  protected timeFormatted: string;
  private interval: any;

  constructor(private callService: CallService, private waitingListService: WaitingListService, private websocketService: WebsocketService, private router: Router) {
    this.fetchCurrentCall();

    this.websocketService.onMessage.subscribe({
      next: (message: WebSocketMessage) => {
        if(message.type == "callStatusChanged")
        {
          if(message.data.status === 'in_call')
          {
            this.fetchCurrentCall();
          }
          else if(message.data.status === 'waiting')
          {
            this.fetchWaitingList();
          }
          else if(message.data.status === 'finished')
          {
            this.removeCall();
          }
        }
      }
    })
  }

  private removeCall() {
    this.currentCall = null;
    this.timeFormatted = null;
    this.time = 0;
    clearInterval(this.interval);
    this.interval = null;
  }

  private fetchCurrentCall() {
    this.callService.currentCall().subscribe({
      next: (data) => {
        if(data.datas)
        {
          this.currentCall = data.datas;
          this.startTimer();
        }
        else {
          this.fetchWaitingList();
        }
      }
    })
  }

  private fetchWaitingList() {
    this.waitingListService.getWaitingListByClientId().subscribe({
      next: (data) => {
        if (data.datas && data.datas !== 0) {
          this.currentWaitingList = data.datas[0];
        }
      }
    })
  }

  private startTimer() {
    if(this.interval)
    {
      clearInterval(this.interval);
    }
    this.time = moment().diff(moment(this.currentCall.start_date), "seconds");
    this.timeFormatted = moment.utc(moment.duration(this.time, "seconds").asMilliseconds()).format("HH:mm:ss")

    this.interval = setInterval(() => {
      this.time++;
      this.timeFormatted = moment.utc(moment.duration(this.time, "seconds").asMilliseconds()).format("HH:mm:ss")
    }, 1000);
  }

  protected readonly moment = moment;

  goToCall() {
    this.router.navigate(["communication-rdv"], {
      state: { callTicket: this.currentCall }
    })
  }

  goToWait() {
    this.router.navigate(["communication-rdv"], {
      state: { waitList: this.currentWaitingList }
    })
  }
}
