<div class="navbar fixed top-0 w-full h-20 lg:h-40 bg-dark-blue
z-1000">

  <app-alerts class="absolute top-4 left-4 w-full lg:w-1/2"></app-alerts>

  <!-- visible only  mobile -->
  @if(authUserService.userData){
  <div class="menu lg:hidden" (click)="toggleMenu()">
    <mat-icon
    class="text-white md:text-4xl lg:text-5xl">menu</mat-icon>
  </div>
  }

  <div class="logo mx-auto">
    <!-- logo mobile -->
    <img alt="logo"
         src="/images/logo-petit.svg"
         class="w-1/4 lg:hidden">

    <!-- logo desktop -->
    <img alt="logo-large"
         src="/images/logo-paris-medium.svg"
         class="hidden lg:block lg:w-3/4 2xl:w-1/2">
  </div>

  @if(authUserService.userData){
    <div class="account lg:mr-20">
      <mat-icon
        class="text-white md:text-6xl lg:text-6xl xl:text-7xl"
        (click)="redirectToAccount()">
        person
      </mat-icon>
      @if(isDesktop()){
        <p (click)="redirectToAccount()">Mon compte</p>
      }
    </div>
  }


</div>

@if (isDesktop()) {
  <app-menu [ngClass]="{'open': true}"></app-menu>
} @else {
  <app-menu [ngClass]="{'open': isMenuOpen}"
            [isMenuOpen]="isMenuOpen">
  </app-menu>
}

