import {Component} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MenuComponent} from "../menu/menu.component";
import {NgClass} from "@angular/common";
import {Router} from "@angular/router";
import {AuthUserService} from "../../../services/auth-user.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertsComponent} from "../../alerts/alerts.component";

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [
    MatIcon,
    MenuComponent,
    NgClass,
    AlertsComponent,
  ],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {

  isMenuOpen = false;

  constructor(  private router: Router,
                protected authUserService: AuthUserService,
                private matDialog: MatDialog) {
    this.authUserService.userInfos().subscribe();
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  isDesktop(): boolean {
    return window.innerWidth >= 1280;
  }
  redirectToAccount() {
    this.isMenuOpen = false;
    this.router.navigate(['/parametres'])
  }

}
