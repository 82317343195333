<div class="{{data.error ? 'error' : 'dialog-confirm'}}">

  <div class="header">
    @if(data.title){
      <h1 class="text-center">{{data.title}}</h1>
    }
    @if(data.withCloseButton){
      <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    }
  </div>

  <div matDialogContent class="content">
    {{data.confirmMessage}}
    @if (data.subTexts) {
      @for (sub of data.subTexts; track sub) {
        <div>{{sub}}</div>
      }
    }
  </div>

  @if(data.cancelButton || data.confirmButton){
    <div matDialogActions class="actions flex justify-around">
      @if(data.cancelButton) {
        <button class="cancel" (click)="cancel()">{{data.cancelButton}}</button>
      }
      @if(data.confirmButton)
      {
        <button class="confirm" (click)="confirm()">{{data.confirmButton}}</button>
      }
    </div>
  }

</div>
