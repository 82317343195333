<div class="menu-container" [ngClass]="{'open': isMenuOpen}">
  @if (authUserService.userData) {
    @if (isDesktop()) {
      <ul>
        <li><a [routerLink]="['/voyants']" routerLinkActive="active-link">RECHERCHER UN VOYANT</a></li>
        <li><a [routerLink]="['/prendre-rdv']" routerLinkActive="active-link">PRENDRE RDV</a></li>
<!--        TODO: commenter pour préprod-->
        <li><a [routerLink]="['/voyance-privee']" routerLinkActive="active-link">VOYANCE PRIVEE</a></li>
        <!--          <li><a>APPEL AUDIOTEL</a></li>-->
        <li>
          <div class="p-2 cursor-pointer" (click)="logout()">Déconnexion</div>
        </li>
      </ul>
    } @else {
      <ul>
        <li>
          <a [routerLink]="['/voyants']"
             routerLinkActive="active-link"
             (click)="handleClick()">
            RECHERCHER UN VOYANT
          </a>
        </li>
        <li><a [routerLink]="['/prendre-rdv']"
               routerLinkActive="active-link"
               (click)="handleClick()">
          PRENDRE RDV
        </a></li>
        <!--        TODO: commenter pour préprod-->
        <li><a [routerLink]="['/voyance-privee']"
               routerLinkActive="active-link"
               (click)="handleClick()">
          VOYANCE PRIVEE
        </a></li>

        <!--    <li><a>APPEL AUDIOTEL</a></li>-->

        <li>
          <a [routerLink]="['/mes-rdv']"
             routerLinkActive="active-link"
             (click)="handleClick()">
            MES RDV / ATTENTES
          </a>
        </li>

        <li>
          <a [routerLink]="['/factures']"
             routerLinkActive="active-link"
             (click)="handleClick()">
            MES FACTURES
          </a>
        </li>

        <li>
          <a [routerLink]="['/parametres']"
             routerLinkActive="active-link"
             (click)="handleClick()">
            PARAMETRES
          </a>
        </li>
        <li>
          <div class="p-2 cursor-pointer" (click)="logout()">Déconnexion</div>
        </li>
      </ul>
    }
  }
</div>

