@if (currentCall) {
  <div id="callBanner" class="w-full h-[5vh]">
    <mat-icon>phone_in_talk</mat-icon>
    <span>Vous êtes en appel avec {{ currentCall.clairvoyant.pseudo }}.</span>
    <span>{{ timeFormatted }}</span>
    <span class="clickToGoToCall" (click)="goToCall()">Cliquer pour revenir à votre appel.</span>
  </div>
} @else if(currentWaitingList) {
  <div id="waitBanner" class="w-full h-[5vh]">
    <mat-icon>phone_in_talk</mat-icon>
    <span>En attente de {{ currentWaitingList.waitingList.voyant.pseudo }}.</span>
    <span class="clickToGoToCall" (click)="goToWait()">Cliquer pour revenir à la page d'attente.</span>
  </div>
}
