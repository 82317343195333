import {Component} from '@angular/core';
import {CdkScrollable} from "@angular/cdk/scrolling";
import {NavBarComponent} from "./nav-bar/nav-bar.component";
import {CallBannerComponent} from "../call-banner/call-banner.component";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-page-layout',
  standalone: true,
  imports: [
    CdkScrollable,
    NavBarComponent,
    CallBannerComponent,
    RouterOutlet
  ],
  templateUrl: './page-layout.component.html',
  styleUrl: './page-layout.component.scss'
})
export class PageLayoutComponent {

}
