import {Component} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {AlertComponent} from "./alert/alert.component";
import {AlertService} from "../../services/alert.service";
import {Alert} from "../../models/alert";

@Component({
  selector: 'app-alerts',
  standalone: true,
  imports: [
    AlertComponent
  ],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss'
})
export class AlertsComponent {
  private unsubscribeAll: Subject<any> = new Subject();
  protected alerts: Alert[] = []
  protected show = false;
  constructor(private alertService: AlertService) {
    alertService.onAlert()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: alert => {
          this.alerts.push(alert);
          this.show = true;
          if(alert.options?.autoClose)
          {
            setTimeout(() => {
              // declenche effet visuel de disparition :
              this.alerts.filter(a => a == alert)[0].dimiss = true;
            }, 2000 - 500)
            setTimeout(() => {
              // retire complètement l'alerte de la liste. :
              this.alerts = this.alerts.filter(a => a !== alert);
              // cache le composant alerts sinon peut empecher click sur ce qu'il y a en dessous
              if(this.alerts.length === 0){
                this.show = false;
              }
            }, 2000)
          }
        }
      })
  }
}
