import { Injectable } from '@angular/core';
import {RestApiService} from "@makeo-packages/mkongtools";
import {Observable} from "rxjs";
import {CallTicket} from "../models/call";

@Injectable({
  providedIn: 'root'
})
export class CallService {

  public apiEndpoint: string = 'call';

  constructor(protected apiService: RestApiService) {
  }

  public currentCall() : Observable<{datas: CallTicket}>
  {
    return this.apiService.get(this.apiEndpoint + "/client", "current",);
  }
}
