import {Component, Input, OnInit} from '@angular/core';
import {NgClass} from "@angular/common";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {AuthUserService} from "../../../services/auth-user.service";
import {MatIcon} from "@angular/material/icon";
import {ConfirmModalComponent} from "../../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-menu',
  standalone: true,
    imports: [
        NgClass,
        RouterLink,
        RouterLinkActive,
        MatIcon
    ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit {
  @Input() isMenuOpen: boolean;
  currentRoute: string = '';

  constructor(
    protected router: Router,
    protected authUserService: AuthUserService,
    private matDialog: MatDialog
  ) {
    this.authUserService.userInfos().subscribe();

    this.router.events.subscribe((event: any) => {
      if (event.url) {
        this.currentRoute = event.url;
      }
    });
  }


  ngOnInit() {
  }

  isDesktop(): boolean {
    return window.innerWidth >= 1280;
  }

  logout() {
    this.matDialog.open(ConfirmModalComponent, {data: {
        title: "Déconnexion",
        confirmMessage: "Confirmer pour poursuivre la déconnexion",
        confirmButton: "Se déconnecter",
        cancelButton: "Annuler"
      }}).afterClosed().subscribe({
      next: (result) => {
        if(result)
        {
          this.authUserService.signOut();
          this.authUserService.userData = null;
        }
      }
    })
  }

  handleClick() {
    this.isMenuOpen = false;
  }


}
